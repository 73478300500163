.visits {
	align-items: center;
	background: transparent;
	color: #fff;
	display: flex;
	flex-flow: column;
	filter: drop-shadow(0 2px 0 rgba(0, 0, 0, 0.5));
	text-shadow: 0 1px #0b0e19;
	position: relative;
	text-align: center;
	z-index: 2;
}
.visits .hostEmoji {
	align-items: center;
	display: flex;
	font-size: 40px;
	height: 60px;
	justify-content: center;
	width: 60px;
}
.visits .hostReferrer {
	color: #ff69b4;
	font-size: 18px;
	font-weight: bold;
	margin-bottom: 4px;
	margin-bottom: 8px;
	text-decoration: none;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
	max-width: 190px;
}
.visits .hostReferrer:hover {
	text-decoration: underline;
}
.visits .details {
	display: flex;
	flex-flow: column;
}
.visits .visitTime span {
	font-size: 10px;
	font-weight: normal;
	opacity: 0.75;
}
