* {
	border: 0;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	-webkit-font-smoothing: antialiased;
}

body,
html {
	height: 100%;
	width: 100%;
}

body {
	background: #181c33;
	background: linear-gradient(
		0deg,
		rgba(2, 0, 36, 1) 0%,
		rgba(24, 28, 51, 1) 100%
	);
	font-family: "Lexend Deca", sans-serif;
	position: relative;

	align-items: center;
	display: flex;
}

canvas {
	opacity: 0.4;
	pointer-events: none;
	position: absolute;
	top: 0;
}

#root {
	width: 100%;
}

.App {
	position: relative;
	width: 100%;
}

.logo_link {
	margin: 0 auto;
	position: relative;
	width: 100%;
	max-width: 403px;
}

.link_tag {
	background: #03a9f4;
	border-bottom: 2px solid #0084bf;
	border-radius: 22px;
	box-shadow: 0 4px 0 0 rgb(0 0 0 / 50%);
	color: #0e0f2d;
	color: #fff;
	cursor: pointer;
	align-items: center;
	display: flex;
	justify-content: center;
	font-family: "Lexend Deca", sans-serif;
	font-size: 18px;
	height: 44px;
	letter-spacing: -0.5px;
	margin: 25px 0 15px;
	padding: 7px 10px 5px;
	position: relative;
	text-align: center;
	user-select: none;
	width: 100%;
	z-index: 2;
}
.link_tag:active,
.link_tag:hover {
	background: #17b7ff;
}
.link_tag:active {
	box-shadow: inset 0 2px 0 #2d84b9;
	border: 0;
	height: 44px;
	overflow: hidden;
}
/* .link_tag span {
	color: #2d84b9;
	display: inline-block;
	position: relative;
	transform: scale(0.6);
}
.link_tag span:nth-child(1n) {
	animation: grow-shrink 2s infinite alternate ease-in-out;
	margin-left: 2px;
	opacity: 0.33;
}
.link_tag span:nth-child(2n) {
	animation: grow-shrink 2s infinite alternate ease-in-out;
	animation-delay: 0.5s;
	opacity: 0.66;
}
.link_tag span:nth-child(3n) {
	animation: grow-shrink 2s infinite alternate ease-in-out;
	animation-delay: 1s;
	opacity: 1;
}
@keyframes grow-shrink {
	from {
		transform: scale(0.6);
	}
	to {
		transform: scale(1.1);
	}
} */

.logo {
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: row;
}
.logo .text {
	color: #fff;
	font-family: "Oswald", sans-serif;
	font-size: 60px;
	font-weight: 600;
	letter-spacing: 2px;
	padding: 0 0 1px 20px;
	text-shadow: 0 4px rgb(0 0 0 / 50%);
	width: 257px;
	z-index: 3;
}

.emoji {
	--emoji-size: 100px;
	height: var(--emoji-size);
	position: relative;
	width: var(--emoji-size);
}
.emoji:before {
	backdrop-filter: blur(2px);
	background-color: rgba(248, 248, 248, 0.05);
	background-position: center;
	background-repeat: no-repeat;
	background-size: 80px;
	border: 1px solid rgba(248, 248, 248, 0.05);
	border-radius: 50%;
	box-sizing: border-box;
	content: "";
	height: var(--emoji-size);
	left: 0;
	position: absolute;
	top: 6px;
	top: 2px;
	width: var(--emoji-size);
	z-index: 1;

	background-image: url("data:image/svg+xml,%3Csvg fill='rgba(248, 248, 248, .1)' height='512pt' viewBox='-11 0 512 512.001' width='512pt' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m462.726562 280.8125-42.976562-24.816406 42.972656-24.808594c12.726563-7.34375 21.835938-19.246094 25.660156-33.511719 3.820313-14.261719 1.878907-29.125-5.464843-41.847656l-32.132813-55.65625c-9.8125-16.996094-28.164062-27.554687-47.890625-27.554687-9.632812 0-19.128906 2.546874-27.46875 7.363281l-42.976562 24.808593v-49.621093c0-30.421875-24.746094-55.167969-55.164063-55.167969h-64.269531c-30.417969 0-55.164063 24.746094-55.164063 55.167969v49.625l-42.980468-24.8125c-8.339844-4.816407-17.835938-7.363281-27.46875-7.363281-19.726563 0-38.078125 10.558593-47.890625 27.554687l-32.132813 55.65625c-7.3437498 12.722656-9.285156 27.585937-5.464844 41.847656 3.824219 14.265625 12.933594 26.167969 25.65625 33.511719l42.980469 24.8125-42.980469 24.8125c-26.339843 15.210938-35.398437 49.015625-20.191406 75.359375l32.132813 55.65625c9.8125 16.996094 28.164062 27.554687 47.894531 27.554687 9.628906 0 19.128906-2.546874 27.46875-7.363281l42.976562-24.8125v49.628907c0 30.417968 24.75 55.164062 55.167969 55.164062h64.265625c30.417969 0 55.167969-24.746094 55.167969-55.164062v-49.625l42.972656 24.808593c8.339844 4.816407 17.839844 7.359375 27.46875 7.359375h.003907c19.726562 0 38.078124-10.558594 47.886718-27.550781l32.132813-55.65625c7.347656-12.722656 9.289062-27.585937 5.464843-41.851563-3.824218-14.261718-12.933593-26.164062-25.65625-33.507812zm0 0'/%3E%3C/svg%3E");
}
.emoji:after {
	animation: emoji-float 5s ease-in-out 1;
	background-color: #ffdd67;
	background-position: center -4px;
	background-repeat: no-repeat;
	background-size: 94px;
	border: 4px solid #ffae36;
	border-radius: 50%;
	box-shadow: 0 5px 0 0px rgba(0, 0, 0, 0.5);
	box-sizing: border-box;
	content: "";
	height: var(--emoji-size);
	image-rendering: -webkit-optimize-contrast;
	pointer-events: none;
	position: absolute;
	transition: box-shadow 1.5s ease-in-out, transform 1.5s ease-in-out;
	width: var(--emoji-size);
	z-index: 2;

	background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' enable-background='new 0 0 64 64'%3E%3Ccircle cx='32' cy='32' r='30' fill='%23ffdd67'/%3E%3Cg fill='%23201e38'%3E%3Cpath d='m28.5 27.9c-1.9-5.1-4.7-7.7-7.5-7.7s-5.6 2.6-7.5 7.7c-.2.5.8 1.4 1.3.9 1.8-1.9 4-2.7 6.2-2.7 2.2 0 4.4.8 6.2 2.7.6.6 1.5-.4 1.3-.9'/%3E%3Cpath d='m50.4 27.9c-1.9-5.1-4.7-7.7-7.5-7.7s-5.6 2.6-7.5 7.7c-.2.5.8 1.4 1.3.9 1.8-1.9 4-2.7 6.2-2.7s4.4.8 6.2 2.7c.5.6 1.5-.4 1.3-.9'/%3E%3Cpath d='m54 37.4c0-.8-.6-1.8-2.4-2.1-4.4-.6-11.1-1.3-19.6-1.3-8.5 0-15.2.7-19.6 1.3-1.7.3-2.4 1.3-2.4 2.1 0 9.6 3.5 12.6 17.8 12.6h8.4c14.3 0 17.8-3 17.8-12.6'/%3E%3C/g%3E%3Cg fill='%23fff'%3E%3Cpath d='m48.9 38.2c.2-.5-.1-1-.5-1.1 0 0-7.4-1.1-16.4-1.1-9 0-16.4 1.1-16.4 1.1-.5.1-.7.6-.5 1.1l1.1 2.9c.2.5.8.9 1.3.9h29.1c.5 0 1.1-.4 1.3-.9l1-2.9'/%3E%3Cpath d='m32 48c6.4 0 15.5 0 15.2-2.1 0-.4-.1-.8-.3-1.3-.2-.5-.3-.7-1.4-.7-2.4 0-25.3 0-27.1 0-1.1 0-1.2.1-1.4.7-.1.5-.2.9-.3 1.3-.2 2.1 8.9 2.1 15.3 2.1'/%3E%3C/g%3E%3C/svg%3E");

	color: #a86d00;
	color: #201e38;
	color: #1d1b31;
	color: #19172b;
	color: #13102c;
	color: #803453;
	color: #ff7d67;
	color: #e9664f;
	color: #ffae36;
	color: #ffab2d;
	color: #e49b2e;
	color: #f7989a;
	color: #4fd1d9;
}

.emoji:hover:after {
	box-shadow: 0 20px 0 0px rgba(0, 0, 0, 0.25);
	transform: scale(1.05) translateY(-12px);
}

@keyframes emoji-float {
	0% {
		box-shadow: 0 5px 0 0px rgba(0, 0, 0, 0.5);
		transform: scale(1) translateY(0px);
	}
	50% {
		box-shadow: 0 20px 0 0px rgba(0, 0, 0, 0.25);
		transform: scale(1.05) translateY(-12px);
	}
	100% {
		box-shadow: 0 5px 0 0px rgba(0, 0, 0, 0.5);
		transform: scale(1) translateY(0px);
	}
}

@media (prefers-reduced-motion: reduce) {
	.emoji:after {
		animation: none;
	}
}

@media (prefers-reduced-motion: no-preference) {
	:focus {
		transition: outline-offset 0.25s ease;
		outline-offset: 4px;
	}
}

.instructions {
	align-items: center;
	color: #ccc;
	display: flex;
	font-size: 0.7rem;
	justify-content: center;
	position: relative;
	text-align: center;
	text-shadow: 0 2px rgb(0 0 0 / 50%);
	z-index: 2;
}

.nav_links {
	margin: 30px auto 25px;
	text-align: center;
}
.nav_links a {
	animation: pulsing-text 6s infinite alternate ease-in-out;
	background-color: rgba(0, 0, 0, 0.15);
	backdrop-filter: blur(2px);
	border-radius: 15px;
	color: #bbb;
	display: inline-block;
	font-size: 11px;
	padding: 8px 12px;
	position: relative;
	text-decoration: none;
	z-index: 2;
}
.nav_links a:hover {
	background-color: rgba(0, 0, 0, 0.25);
}
.nav_links span {
	font-size: 10px;
	margin-right: 6px;
}
.navSeperator {
	display: inline-block;
	height: 10px;
	margin: 0 6px;
}
@keyframes pulsing-text {
	0% {
		color: #f8f8f8;
	}
	100% {
		color: #bbb;
	}
}
