.wizard_popup {
	--wizard-anim: 0.25s all ease-in-out;
	--anim-movement: 20px;
	--max-width: 620px;
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	left: 0;
	opacity: 0;
	pointer-events: none;
	position: fixed;
	transition: var(--wizard-anim);
	top: 0;
	width: 100%;
	z-index: 4;
}
.wizard_popup::after {
	background: rgba(6, 9, 20, 0.5);
	backdrop-filter: blur(2px);
	content: "";
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	transition: var(--wizard-anim);
	width: 100%;
}
.wizard_popup.display {
	opacity: 1;
	pointer-events: all;
}
.wizard_popup.display::after {
	backdrop-filter: blur(2px);
	content: "";
	transition: var(--wizard-anim);
}
.wizard_popup h1 {
	color: #eee;
	margin-bottom: var(--anim-movement);
	padding: 0 0 10px;
	position: relative;
	text-shadow: 0 2px rgb(0 0 0 / 50%);
	transition: var(--wizard-anim);
	width: 100%;
	max-width: var(--max-width);
	z-index: 1;
}
.wizard_popup.display h1 {
	margin: 0;
}

/*
	Wizard Steps title and Dots n Dashes
*/
.wizard_steps {
	color: #fff;
	margin-top: var(--anim-movement);
	transition: var(--wizard-anim);
	width: 100%;
	max-width: var(--max-width);
	z-index: 1;
}
.wizard_popup.display .wizard_steps {
	margin: 0;
}
.wizard_steps .step_dots {
	align-items: center;
	display: flex;
	justify-content: center;
	margin: 15px 0 0;
	position: relative;
}
.wizard_steps .step_dots .dot {
	--dot-size: 12px;
	background: #eee;
	box-shadow: 0 2px #bbb;
	border-radius: 50%;
	height: var(--dot-size);
	transform: scale(1);
	transition: var(--wizard-anim);
	width: var(--dot-size);
}
.wizard_steps .step_dots .dot.active,
.wizard_steps .step_dots .dot:first-of-type {
	background: #03a9f4;
	box-shadow: 0 2px #0084bf;
}
.wizard_steps .step_dots .dot.active {
	transform: scale(1.3);
}
.wizard_steps .step_dots .dash {
	background: #eee;
	box-shadow: 0 1px #888;
	flex: auto;
	height: 2px;
	margin-top: 2px;
	transition: var(--wizard-anim);
	width: 50px;
}
.wizard_steps .step_dots .dash.active {
	background: #03a9f4;
	box-shadow: 0 2px #0084bf;
}
.wizard_steps.complete .step_dots .dot.active,
.wizard_steps.complete .step_dots .dash.active {
	--wizard-complete-anim-duration: 0.3s;
	--wizard-complete-anim: complete var(--wizard-complete-anim-duration)
		ease-in-out;
	background: #ffdd67;
	box-shadow: 0 2px #dc8f1c;
}
.wizard_steps.complete .step_dots .dot.active:first-of-type {
	animation: var(--wizard-complete-anim);
	animation-delay: 0.3s;
}
.wizard_steps.complete .step_dots .dot.active:nth-child(3) {
	animation: var(--wizard-complete-anim);
	animation-delay: calc(var(--wizard-complete-anim-duration) * 2 + 0.2s);
}
.wizard_steps.complete .step_dots .dot.active:nth-child(5) {
	animation: complete 0.3s ease-in-out;
	animation-delay: calc(var(--wizard-complete-anim-duration) * 3 + 0.3s);
}
.wizard_steps.complete .step_dots .dot.active:nth-child(7) {
	animation: var(--wizard-complete-anim);
	animation-delay: calc(var(--wizard-complete-anim-duration) * 4 + 0.4s);
}
@keyframes complete {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.8);
	}
	100% {
		transform: scale(1.3);
	}
}

/*
	Wizard Step content
*/
.wizard_content {
	background: #fff;
	border-bottom: 2px solid #ccc;
	border-radius: 4px;
	box-shadow: 0 4px 0 0 rgb(0 0 0 / 50%);
	color: #0e0f2d;
	padding: 30px;
	transform: scale(0.9);
	transition: var(--wizard-anim);
	width: 100%;
	max-width: var(--max-width);
	z-index: 5;
}
.wizard_popup.display .wizard_content {
	transform: scale(1);
}
.wizard_content h3 {
	color: #090e15;
	font-family: "Lexend Deca";
	font-weight: bold;
	font-size: 18px;
	margin-bottom: 15px;
}
.wizard_content .step_copy {
	--set-height: 200px;
	margin: 0 0 10px;
	min-height: var(--set-height);
	max-height: var(--set-height);
	overflow-y: auto;
}
.wizard_content .step_copy p {
	color: #222;
	font-family: "lexend";
	font-size: 13px;
	line-height: 1.5;
	margin-bottom: 15px;
}
.wizard_content .step_copy strong {
	color: #e91e63;
}
.wizard_content .step_copy a {
	border-bottom: 1px dashed #ffae36;
	border-color: #03a9f4;
	color: #03a9f4;
	font-weight: bold;
	text-decoration: none;
}
.wizard_content .step_copy code {
	background: #090e15;
	border-radius: 2px;
	color: #fff;
	display: table;
	margin: 15px auto;
	padding: 10px 15px;
	width: 100%;
}
.wizard_content .step_copy code.shrink {
	font-size: 11px;
	margin: 15px auto 0;
	padding: 15px 10px;
}

/*
	Wizard Buttons
	Close - Back - Next - Submit
*/
.wizard_buttons {
	align-items: center;
	display: flex;
	height: 38px;
	justify-content: space-between;
	margin-top: 10px;
}
.wizard_buttons button {
	border-bottom: 2px solid;
	border-radius: 20px;
	cursor: pointer;
	font-family: "Lexend Deca";
	font-size: 14px;
	height: 100%;
	letter-spacing: 1px;
	padding: 0 18px;
	width: 77px;
}
.wizard_buttons button:active {
	border-bottom: 0;
}
.wizard_buttons button.close {
	background: #ddd;
	border-color: #bbb;
	color: #888;
	margin: 0;
}
.wizard_buttons button.close:hover {
	background: #e8e8e8;
}
.wizard_buttons button.next {
	background: #03a9f4;
	border-bottom: 2px solid #0084bf;
	color: #fff;
}
.wizard_buttons button.next:hover,
.wizard_buttons button.next:active {
	background: #17b7ff;
}
.wizard_buttons button.next:active {
	border-bottom: 0;
}
.wizard_buttons button.prev {
	background: #11bbab;
	border-color: #009688;
	color: #fff;
}
.wizard_buttons button.prev:hover,
.wizard_buttons button.prev:active {
	background: #16c7b7;
	color: #fff;
}
.wizard_buttons button.explore {
	background: #e91e63;
	border-color: #b30641;
	color: #fff;
	width: auto;
}
.wizard_buttons button.explore:hover,
.wizard_buttons button.explore:active {
	background: #f53073;
	color: #fff;
}
.wizard_buttons button.explore span {
	font-size: 19px;
	line-height: 0;
	position: relative;
	top: 1px;
}
